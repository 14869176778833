import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { IsActiveMatchOptions, Router } from '@angular/router';
import { LocalizeRouterService } from '@gilsdav/ngx-translate-router';
import {
  ReplaySubject,
  merge,
  debounceTime,
  distinctUntilChanged,
  map,
  takeUntil,
  take,
  BehaviorSubject,
  first,
  fromEvent,
  switchMap,
  skipUntil,
  delay
} from 'rxjs';
import { SearchPanelComponent } from '@app/shared/components/search-panel/search-panel.component';
import { AppSettingsService, AuthService, BreakpointsService, GtagService, UserService } from '@app/shared/services';
import { Country, UserRole } from '@app/shared/models';
import { getLanguageName, getSectionLink, getServicesLinkWithCountry } from '@app/shared/utils';
import { COMPANY_NAME } from '@app/shared/const/settings';
import { LazyComponentDirective } from '@app/shared/directives/lazy-component.directive';
import { RegionSelectComponent } from '@app/shared/components/region-select/region-select.component';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { ContextMenuItem, Sections } from '@app/shared/types';
import { environment } from 'src/environments/environment';
import { SharedModule } from '@shared/shared.module';
import { SigninComponent } from '@comp/signin/signin.component';
import { ContextMenuModule } from '@comp/context-menu/context-menu.module';
import { NavigationService } from '@shared/services/navigation.service';
import { TooltipModule } from '@comp/tooltip/tooltip.module';
import { ButtonComponent } from '@shared/controls/button/button.component';

@Component({
  selector: '[ln-header]',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [SharedModule, ButtonComponent, SigninComponent, ContextMenuModule, TooltipModule, SearchPanelComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {
  sticky$ = new BehaviorSubject(false);
  isShowRegion$ = new BehaviorSubject<boolean | null>(null);
  showedInDomSignIn$ = new BehaviorSubject<boolean>(true);

  isShowSearch = false;
  destroySearch = false;
  isShowSignIn = false;
  isShowRegionTip: boolean | null = null;
  isCountryNotActive = false;
  selectedCountry?: Country;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  loaded$ = new BehaviorSubject(false);

  Sections = Sections;
  getSectionLink = getSectionLink;
  getServicesLinkWithCountry = getServicesLinkWithCountry;
  disableCountries = environment.disableCountries;

  UserRole = UserRole;
  COMPANY_NAME = COMPANY_NAME;

  menuItems: ContextMenuItem[] = [
    { name: 'questions', action: () => this.router.navigate([this.localize.translateRoute('/client/questions')]) },
    {
      name: 'orders',
      action: () => this.router.navigate([this.localize.translateRoute('/client/orders')])
    },
    {
      name: 'rfq',
      action: () => this.router.navigate([this.localize.translateRoute('/client/rfq')])
    },
    { name: null, action: null },
    {
      name: 'profile',
      action: () => this.router.navigate([this.localize.translateRoute('/profile')])
    },
    { name: null, action: null },
    {
      name: 'logout',
      action: () => {
        this.userService.logout();
        this.router.navigate([this.localize.translateRoute('/')]);
      }
    }
  ];

  @Input() menuMobileOpened = false;
  @Input() chatOpened = false;

  @Output() toggleBurger = new EventEmitter<boolean>();
  @Output() toggleChat = new EventEmitter<boolean>();

  @ViewChild('searchPanel') searchPanelRef!: SearchPanelComponent;
  @ViewChild('regionPanel', { static: true }) regionPanelRef!: LazyComponentDirective;
  @ViewChild('regionPanelMobile', { static: true }) regionPanelMobileRef!: LazyComponentDirective;

  searchModule?: any;

  regionPanel?: null | ComponentRef<RegionSelectComponent>;
  regionPanelMobile?: null | ComponentRef<RegionSelectComponent>;
  regionModule?: any;
  isMobile?: boolean;
  showLoader = true;

  getLanguageName = getLanguageName;

  constructor(
    public localize: LocalizeRouterService,
    private router: Router,
    protected settings: AppSettingsService,
    protected userService: UserService,
    protected authService: AuthService,
    private gtagService: GtagService,
    public viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: any,
    protected breakpoints: BreakpointsService,
    private viewportScroller: ViewportScroller,
    private navigation: NavigationService
  ) {}

  get linkMyAccount() {
    return getSectionLink(this.settings.settings.lastAccountSection) ?? '/client/questions';
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.showLoader = false;
      this.authService.isAuthorized$.pipe(first()).subscribe(() => {
        this.loaded$.next(true);
      });

      if (!this.disableCountries) {
        this.settings.country$.pipe(takeUntil(this.destroyed$)).subscribe((country) => {
          this.selectedCountry = country;
          this.cdr.detectChanges();
        });

        this.settings.settings$
          .pipe(
            map((settings) => settings.realCountry),
            distinctUntilChanged(),
            take(1)
          )
          .subscribe((realCountry) => {
            this.isCountryNotActive = !realCountry?.active;
            this.cdr.markForCheck();
          });

        this.settings.settings$
          .pipe(
            takeUntil(this.destroyed$),
            map((settings) => settings.countryAccepted),
            distinctUntilChanged()
          )
          .subscribe((accepted) => {
            setTimeout(() => {
              this.isShowRegionTip = !accepted;
              this.cdr.detectChanges();
            }, 100);
          });
      }

      this.breakpoints.mobile$?.pipe(takeUntil(this.destroyed$)).subscribe((isMobile) => (this.isMobile = isMobile));

      setTimeout(() => {
        const scrolls = fromEvent(document, 'scroll');

        merge(
          scrolls.pipe(takeUntil(this.navigation.isBack$)),
          scrolls.pipe(
            skipUntil(this.navigation.isBack$),
            delay(500),
            switchMap(() => scrolls)
          )
        )
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => this.changeHeaderHeight());
      });

      this.settings.isShowSignIn$.pipe(takeUntil(this.destroyed$), delay(500)).subscribe((signInParams) => {
        this.isShowSignIn = signInParams?.isShow || false;
      });
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  changeHeaderHeight(setThinFirce = false) {
    const [, y] = this.viewportScroller.getScrollPosition();
    const maxOffset = this.isMobile ? 17 : 30;

    this.sticky$.next(setThinFirce || y >= maxOffset);
  }

  onBurger() {
    if (!this.menuMobileOpened) setTimeout(() => this.toggleBurger.emit(), 10);
  }

  onChat() {
    if (!this.chatOpened) setTimeout(() => this.toggleChat.emit(), 10);
  }

  async onRegion(event: Event) {
    this.settings.setCountryAccepted();
    this.isShowRegionTip = false;
    this.isShowRegion$.pipe(take(1)).subscribe((val) => this.isShowRegion$.next(!val));
    await this.addRegionPanel();
    this.cdr.detectChanges();
    setTimeout(() => {
      this.regionPanel?.instance.form.nativeElement.focus();
    }, 100);
  }

  async loadRegionModule() {
    if (!this.regionModule) {
      this.regionModule = null;
      this.regionModule = await import(
        /* webpackPrefetch: true */ '@app/shared/components/region-select/region-select.component'
      );
    }
  }

  async addRegionPanel() {
    if (!!this.regionPanel) return;
    await this.loadRegionModule();
    this.regionPanel = this.regionPanelRef.viewContainerRef.createComponent(this.regionModule.RegionSelectComponent);
    this.regionPanelMobile = this.regionPanelMobileRef.viewContainerRef.createComponent(
      this.regionModule.RegionSelectComponent
    );
    merge(this.regionPanel.instance.closeForm, this.regionPanelMobile.instance.closeForm)
      .pipe(takeUntil(this.destroyed$), debounceTime(50))
      .subscribe((val) => {
        this.isShowRegion$.next(val);
      });
  }

  switchSearch(event: Event) {
    event.stopPropagation();
    this.isShowSearch = !this.isShowSearch;
    if (this.isShowSearch) {
      this.gtagService.event('Search form opening', {
        'event_category': 'Profitable Engagement'
      });
      setTimeout(() => {
        this.searchPanelRef?.textInputElement.inputElement.nativeElement.focus();
      }, 100);
    }
  }

  onKeyUpSearch(event: KeyboardEvent) {
    if (event.code === 'Enter') this.switchSearch(event);
  }

  onKeyUpRegion(event: KeyboardEvent) {
    if (event.code === 'Enter') {
      this.onRegion(event);
    }
  }

  isActive(path: string | string[]): boolean {
    const options: IsActiveMatchOptions = {
      matrixParams: 'ignored',
      queryParams: 'ignored',
      paths: 'subset',
      fragment: 'ignored'
    };
    return Array.isArray(path)
      ? path.some((curPath) => this.router.isActive(this.localize.translateRoute(curPath) as string, options))
      : this.router.isActive(this.localize.translateRoute(path) as string, options);
  }

  onShowSignIn() {
    if (this.isShowSignIn) return;
    this.settings.isShowSignIn$.next({ isShow: true, isRegister: false });
  }

  onCloseSignIn() {
    if (this.isShowSignIn) {
      setTimeout(() => {
        this.showedInDomSignIn$.next(false);
        setTimeout(() => {
          this.showedInDomSignIn$.next(true);
        });
      }, 300);
    }

    this.settings.isShowSignIn$.next({ isShow: false, isRegister: false });
  }

  onCloseSearchForm() {
    this.isShowSearch = false;
  }
}
