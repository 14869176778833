<div class="app-container">
  <div class="footer__top">
    <div class="footer__nav">
      <div class="footer__nav-box">
        <a
          [routerLink]="
            getSectionLink(Sections.Lawyers, (settings.country$ | async)?.shortCode?.toLowerCase() || '') | localize
          "
          >{{ 'layouts.footer.menu.lawyers' | translate }}</a
        >
        <a [routerLink]="getSectionLink(Sections.LawFirms, settings.countryCode || '') | localize">{{
          'layouts.footer.menu.lawFirms' | translate
        }}</a>
        <a [routerLink]="getServicesLinkWithCountry(Sections.Services, settings.countryCode || '') | localize">{{
          'layouts.footer.menu.paidServices' | translate
        }}</a>
        <a [routerLink]="'/ask-a-lawyer' | localize">{{ 'layouts.footer.menu.freeAdvice' | translate }}</a>
      </div>
      <div class="footer__nav-box">
        <span>{{ 'layouts.footer.menu.howItWorks' | translate }}</span>
        <a [routerLink]="'/how-it-works/for-clients' | localize" class="footer__nav-thin"
          >• {{ 'layouts.footer.menu.forClients' | translate }}</a
        >
        <a [routerLink]="'/how-it-works/for-lawyers' | localize" class="footer__nav-thin"
          >• {{ 'layouts.footer.menu.forLawyers' | translate }}</a
        >
      </div>
      <div class="footer__nav-box">
        <a [routerLink]="getSectionLink(Sections.SelfHelp, settings.countryCode || '') | localize">{{
          'layouts.footer.menu.selfHelp' | translate
        }}</a>
        <a
          [routerLink]="getSectionLink(Sections.Questions, settings.countryCode || '') | localize"
          class="footer__nav-thin"
          >• {{ 'layouts.footer.menu.qa' | translate }}</a
        >
        <a
          [routerLink]="getSectionLink(Sections.LegalBlog, settings.countryCode || '') | localize"
          class="footer__nav-thin"
          >• {{ 'layouts.footer.menu.legalBlog' | translate }}</a
        >
        <a [routerLink]="getSectionLink(Sections.Laws, settings.countryCode || '') | localize" class="footer__nav-thin"
          >• {{ 'layouts.footer.menu.laws' | translate }}</a
        >
        <a [routerLink]="getSectionLink(Sections.LegalDictionary) | localize" class="footer__nav-thin"
          >• {{ 'layouts.footer.menu.legalDict' | translate }}</a
        >
      </div>
      <div class="footer__nav-box">
        <a [routerLink]="'/contact-us' | localize">{{ 'layouts.footer.menu.contactUs' | translate }}</a>
        <a [routerLink]="'/about-us' | localize">{{ 'layouts.footer.menu.aboutUs' | translate }}</a>
        <a [routerLink]="'/terms' | localize">{{ 'layouts.footer.menu.termsUse' | translate }}</a>
        <a [routerLink]="'/privacy-policy' | localize">{{ 'layouts.footer.menu.privacyPolicy' | translate }}</a>
      </div>
    </div>
    <div class="footer__dialog default-layout__side">
      <div class="footer__dialog-question">{{ 'layouts.footer.promo.title' | translate }}</div>
      <p>{{ 'layouts.footer.promo.description' | translate }}</p>
      <div class="footer__dialog-buttons">
        <a [routerLink]="'/for-lawyers' | localize" fragment="register-form" ln-button class="outlined orange">{{
          'layouts.footer.promo.buttonRegister' | translate
        }}</a>
        <a ln-button-arrow [routerLink]="'/how-it-works/for-lawyers' | localize" class="text orange">
          {{ 'layouts.footer.promo.buttonArrow' | translate }}
        </a>
      </div>
    </div>
    @if (showBanner && (breakpoints.mobile$ | async)) {
      <ln-banner-popular-questions class="footer__banner hide-laptop layout-element"></ln-banner-popular-questions>
    }
  </div>
  <div class="footer__bottom">
    <span>{{ currentYear }} © {{ copyright }} </span>
    <div class="footer__social">
      <a
        [href]="item.link"
        class="footer__social-item"
        *ngFor="let item of social"
        target="_blank"
        rel="nofollow"
        [title]="item.title"
      >
        <ln-icon [name]="item.icon" width="20px" height="20px"></ln-icon>
      </a>
    </div>
  </div>
</div>
