<div class="header" [class]="{ sticky: sticky$ | async, 'burger-opened': menuMobileOpened, 'chat-opened': chatOpened }">
  <div class="header__loader" [hidden]="!showLoader"></div>
  <div class="app-container-full">
    <div class="header__wrapper">
      <div class="header__mobile">
        <ln-icon class="header__burger" (click)="onBurger()" width="20px" height="14px" name="burger"></ln-icon>
        <a [routerLink]="'/' | localize" class="header__mobile-logo">
          <img src="/images/logo.svg" [alt]="COMPANY_NAME" width="100" height="25" />
        </a>
        <div
          [class]="{ 'header__region-tip-wrapper-show': !!isShowRegionTip }"
          class="header__region-tip-wrapper"
          [hidden]="disableCountries"
        >
          <ng-container [ngTemplateOutlet]="regionTip"></ng-container>
        </div>
        <div
          [hidden]="disableCountries"
          class="header__region-overlay"
          [class]="{ 'header__region-overlay-show': isShowRegion$ | async }"
        >
          <ng-template ln-lazy-component #regionPanelMobile="lazyDirective"></ng-template>
        </div>
        <ln-icon name="chat" class="header__chat" width="21px" height="19px" (click)="onChat()"></ln-icon>
      </div>

      <a [routerLink]="'/' | localize" class="header__logo">
        <img src="/images/logo.svg" [alt]="COMPANY_NAME" width="100" height="25" />
      </a>
      <nav class="header__nav">
        <a [routerLink]="'/ask-a-lawyer' | localize" class="header__link" routerLinkActive="header__link-active">{{
          'layouts.header.freeAdvice' | translate
        }}</a>
        <a
          [routerLink]="getSectionLink(Sections.Lawyers, settings.countryCode || '') | localize"
          class="header__link"
          routerLinkActive="header__link-active"
          >{{ 'layouts.header.lawyers' | translate }}</a
        >
        <a
          [routerLink]="getServicesLinkWithCountry(Sections.Services, settings.countryCode || '') | localize"
          class="header__link"
          routerLinkActive="header__link-active"
          >{{ 'layouts.header.paidServices' | translate }}</a
        >
        <a
          [routerLink]="getSectionLink(Sections.SelfHelp, settings.countryCode || '') | localize"
          class="header__link"
          [class]="{
            'header__link-active': isActive([
              '/self-help',
              '/search',
              '/questions',
              '/legal-blog',
              '/legislation',
              '/legal-dictionary'
            ])
          }"
          >{{ 'layouts.header.selfHelp' | translate }}</a
        >
      </nav>
    </div>
    <div class="header__wrapper header__desktop">
      <a [routerLink]="'/for-lawyers' | localize" class="header__link" routerLinkActive="header__link-active">{{
        'layouts.header.forLawyers' | translate
      }}</a>
      <div
        class="header__region"
        role="button"
        tabindex="0"
        (keyup)="onKeyUpRegion($event)"
        aria-label="Change language"
        aria-haspopup="true"
        [attr.aria-expanded]="isShowRegion$ | async"
        aria-controls="region-form"
      >
        <ng-container [ngTemplateOutlet]="regionTip"></ng-container>
        <div
          class="header__region-overlay"
          role="listbox"
          [class]="{ 'header__region-overlay-show': isShowRegion$ | async }"
          [attr.aria-hidden]="!(isShowRegion$ | async)"
          id="region-form"
        >
          <ng-template ln-lazy-component #regionPanel="lazyDirective"></ng-template>
        </div>
      </div>
      <div
        class="header__search"
        [class]="{ fade: isShowSearch }"
        [attr.aria-expanded]="isShowSearch"
        aria-controls="search-form"
        tabindex="0"
        aria-label="Search"
        aria-haspopup="true"
        role="button"
        (keyup)="onKeyUpSearch($event)"
      >
        <ln-icon
          class="header__link"
          name="magnific"
          width="17px"
          height="18px"
          (click)="switchSearch($event)"
        ></ln-icon>
        <div
          class="header__search-overlay"
          [class]="{ 'header__search-overlay-show': isShowSearch }"
          id="search-form"
          role="search"
        >
          @defer (when isShowSearch; on timer(3s)) {
            @if (!(breakpoints.mobile$ | async)) {
              <ln-search-panel
                #searchPanel
                [showFilters]="true"
                [attr.aria-hidden]="!isShowSearch"
                (closeForm)="onCloseSearchForm()"
                [opened]="isShowSearch"
              ></ln-search-panel>
            }
          }
        </div>
      </div>
      <div class="header__sign" [class]="{ 'header__link': (authService.isAuthorized$ | async) }">
        <a
          ln-button
          tabindex="0"
          *ngIf="authService.isAuthorized$ | async; else guestLink"
          class="header__sign-button outlined"
          [class]="{ 'header__link-active': isActive('/client') }"
          [small]="true"
          [routerLink]="(userService.isClient ? linkMyAccount : '/client/profile') | localize"
        >
          <span class="header__sign-button-text" [class]="{ 'collapse': (loaded$ | async) === false }">{{
            'layouts.header.myAccount' | translate
          }}</span>
        </a>
        <ng-template #guestLink>
          <button
            ln-button
            tabindex="0"
            class="header__sign-button outlined"
            [small]="true"
            (click)="onShowSignIn()"
            [attr.aria-expanded]="isShowSignIn"
            aria-controls="signin-form"
          >
            <span class="header__sign-button-text" [class]="{ 'collapse': (loaded$ | async) === false }">{{
              'layouts.header.signIn' | translate
            }}</span>
          </button>
        </ng-template>
      </div>
    </div>
  </div>
  @defer (on idle) {
    <div
      class="header__sign-overlay"
      [class]="{ 'header__sign-overlay-show': (settings.isShowSignIn$ | async)?.isShow }"
      ln-click-out
      (clickOut)="onCloseSignIn()"
      id="signin-form"
    >
      @if (showedInDomSignIn$ | async) {
        <ln-signin
          [attr.aria-hidden]="!(settings.isShowSignIn$ | async)?.isShow"
          (closed)="onCloseSignIn()"
          [isRegister]="(settings.isShowSignIn$ | async)?.isRegister"
        ></ln-signin>
      }
    </div>
  }
</div>

<ng-template #regionTip>
  <div class="header__link header__language" (click)="onRegion($event)">
    <div
      *ngIf="!disableCountries"
      class="header__region-tooltip"
      [lnTooltip]="selectedCountry?.title || ''"
      [lnTooltipDisabled]="!selectedCountry?.title || !!isShowRegionTip"
    >
      <span class="header__region-flag" class="fi fi-{{ selectedCountry?.shortCode | lowercase }}"></span>
    </div>
    {{ localize.parser.currentLang | uppercase }}
  </div>
  <div
    class="header__region-tip-overlay"
    *ngIf="!disableCountries"
    [class]="{ 'header__region-tip-overlay-show': !!isShowRegionTip }"
  >
    <div class="header__region-tip">
      {{
        (isCountryNotActive ? 'layouts.header.regionTipAbsentCountry' : 'layouts.header.regionTip')
          | translate: { country: selectedCountry?.title, language: getLanguageName(localize.parser.currentLang) }
      }}
      <ln-icon
        name="cross"
        width="10px"
        height="10px"
        (click)="settings.setCountryAccepted()"
        class="header__region-tip-close"
      ></ln-icon>
    </div>
  </div>
</ng-template>
